import { useState, useEffect } from 'react'
import { throttle } from 'lodash'

export function useWindowSize() {
  const [size, setSize] = useState([0, 0])

  useEffect(() => {
    const handleResize = throttle(() => {
      setSize([window.innerWidth, window.innerHeight])
    }, 600)

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return size
}
