import styles from '@/styles/components/common/Testimonial.module.scss'
import trustpilot_img from '@/public/images/trustpilot.png'
import Image from 'next/image'
import { imageLoader } from '@/loaders/index'

interface Props {
  className?: string,
  large?: boolean,
  stars?: boolean,
  numberOfReviews?: string,
  changePosition?: boolean,
  vertical?: boolean
}

export const CustomTrustpilot: React.FC<Props> = ({
  className = '',
  large = false,
  stars = false,
  vertical = false,
  numberOfReviews = '',
  changePosition = false
}) => {
  return (
    <div
      className={`${className} trustpilot-custom font--accent shrink-0`}
    >
      <a
        href={'https://www.trustpilot.com/review/commitaction.com?utm_medium=trustbox&utm_source=MicroTrustScore'}
        target='_blank'
        rel='noreferrer'
        className={`flex items-center ${vertical ? 'flex-col' : ''}`}
      >
        {!stars && <span className={`${large ? 'text-[14px]' : 'text-[9px]'} mx-[4px]`}>4.9 out of 5</span>}
        {!changePosition && <span className={`${large ? 'text-[15px]' : 'text-[10px]'} ${vertical ? 'mb-[3px] font--primary italic' : ''} font-semibold`}>Excellent</span>}
        {stars && <div className={`flex ${changePosition ? '' : 'ml-[10px]'}`}>
          <span className={styles.testimonial__star}>
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.00002 9.15094L9.75474 8.45283L10.9057 12L7.00002 9.15094ZM13.3396 4.56604H8.49059L7.00002 0L5.50946 4.56604H0.6604L4.58493 7.39622L3.09436 11.9623L7.01889 9.13207L9.43399 7.39622L13.3396 4.56604Z" fill="white"></path>
            </svg>
          </span>
          <span className={styles.testimonial__star}>
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.00002 9.15094L9.75474 8.45283L10.9057 12L7.00002 9.15094ZM13.3396 4.56604H8.49059L7.00002 0L5.50946 4.56604H0.6604L4.58493 7.39622L3.09436 11.9623L7.01889 9.13207L9.43399 7.39622L13.3396 4.56604Z" fill="white"></path>
            </svg>
          </span>
          <span className={styles.testimonial__star}>
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.00002 9.15094L9.75474 8.45283L10.9057 12L7.00002 9.15094ZM13.3396 4.56604H8.49059L7.00002 0L5.50946 4.56604H0.6604L4.58493 7.39622L3.09436 11.9623L7.01889 9.13207L9.43399 7.39622L13.3396 4.56604Z" fill="white"></path>
            </svg>
          </span>
          <span className={styles.testimonial__star}>
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.00002 9.15094L9.75474 8.45283L10.9057 12L7.00002 9.15094ZM13.3396 4.56604H8.49059L7.00002 0L5.50946 4.56604H0.6604L4.58493 7.39622L3.09436 11.9623L7.01889 9.13207L9.43399 7.39622L13.3396 4.56604Z" fill="white"></path>
            </svg>
          </span>
          <span className={styles.testimonial__star}>
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.00002 9.15094L9.75474 8.45283L10.9057 12L7.00002 9.15094ZM13.3396 4.56604H8.49059L7.00002 0L5.50946 4.56604H0.6604L4.58493 7.39622L3.09436 11.9623L7.01889 9.13207L9.43399 7.39622L13.3396 4.56604Z" fill="white"></path>
            </svg>
          </span>
        </div>}
        {changePosition && <span className={`${large ? 'text-[15px]' : 'text-[10px]'} ${vertical ? 'mb-[3px] font--primary italic' : ''} font-semibold ml-[10px]`}>Excellent&nbsp;&nbsp;&nbsp;| </span>}
        <div className={`${vertical ? 'mt-[10px]' : ''}`}>
          {numberOfReviews && <span className='mx-[10px] text-[15px]'><span className='font-bold'>{numberOfReviews}</span> reviews on</span>}
          <span className={`${large ? 'text-[14px]' : 'text-[9px]'}`}>
            <Image
              loader={imageLoader}
              src={trustpilot_img}
              alt="trustpilot icon"
              width={large ? 16 : 12}
              height={large ? 16 : 12}
              priority={true}
              className='!top-[2px]'
            />
            <span className='ml-[2px]'>Trustpilot</span>
          </span>
        </div>
      </a>
    </div>
  )
}
